// -----------MY CSS Start--------
@import "./variable.scss";

.Mui-focused {
  // border-color: blue !important;
}

.otp-input.error-border .MuiOutlinedInput-root,
.error-border,
.error-border.Mui-focused {
  border-color: #e11d29 !important;
}

.post-tab .MuiTab-textColorPrimary.Mui-selected svg path {
  stroke: white;
}

.post-tab .MuiTab-textColorPrimary .MuiTab-iconWrapper {
  margin-bottom: 0 !important;
}

.suggestion-tab .MuiTab-textColorPrimary.Mui-selected svg path {
  stroke: white;
}

.suggestion-tab .MuiTab-textColorPrimary .MuiTab-iconWrapper {
  margin-bottom: 0 !important;
}

.input-cover-file {
  opacity: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.input-profile-file {
  opacity: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  width: 154px;
  height: 154px;
  cursor: pointer;
}

.cover-image #cover {
  max-width: 100%;
  // width: 600px;
  max-height: 200px;
  border-radius: 16px;
  object-fit: cover;
}

.new-profile-image {
  position: relative;
  top: -93px;
  left: 40px !important;
  height: 128px;
  width: 128px;
  border-radius: 50%;
  border: 5px solid #fff;
}

.creator-profile img {
  height: 112px;
  width: 112px;
  border-radius: 50%;
}

.profile-btn img {
  width: 100%;
}

.input-profile-file {
  position: relative;
  top: -73px;
  left: -172px;
}

label {
  cursor: pointer;
}

.sidemenu-profile {
  height: 60px;
  width: 60px;
  min-height: 60px;
  width: 60px;
  border-radius: 50%;
}

.sidemenu-profile img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.sidemenu-more-profile img {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 900 !important;
}

.profile-user {
  height: 128px;
  width: 128px;
  border-radius: 50%;
  object-fit: cover;
}

// .camera-btn {
//   left: 110px !important;
//   bottom: 71px !important;
// }

.fix-profile-btn {
  left: 125px !important;
  bottom: 70px !important;
}

.cms-content {
  min-height: calc(100vh - 80px - 80px);
}

.MuiButton-containedPrimary.btn {
  border-radius: 8px !important;
  min-height: 40px !important;
}

.MuiButton-outlinedPrimary.btn {
  border-radius: 8px !important;
  min-height: 43px !important;
}

.user-data-container h3,
.user-data-container p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-data-container {
  max-width: calc(100% - 60px - 2rem);
}

.otp-color {
  color: #65717b !important;
}

.profile-skeleton {
  position: relative;
  bottom: 70px;
  left: 20px;
}

.mr-4 {
  margin-right: 16px !important;
}

.Swal-title-class {
  font-size: 20px;
}

.sidebar .MuiListItemButton-root.MuiButtonBase-root.active .MuiListItemIcon-root svg path {
  stroke: $primary;
}

.sidebar {
  z-index: 0 !important;
}

.loading-img {
  max-width: 600px;
  width: calc(100vw - 100px);
  margin: 0 auto;
}

.posttabbtn .MuiTabs-root span {
  display: none;
}

.loading-img img {
  width: 100%;
  transform-origin: center top;
  animation: scale 2000ms ease-in-out infinite;
}

.tooltip {
  font-size: 12px !important;
  border-radius: 10px !important;
}

tbody tr.ease-linear:hover {
  background-color: rgb(226 232 240 / 0.5) !important;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.site-notification:hover {
  background-color: #f2f8fc;
}

.site-notification label {
  display: flex;

  .MuiFormControlLabel-root .MuiRadio-colorPrimary {
    top: 0px !important;
  }
}

.language-img img {
  height: 20px;
}

.border-success div:first-child {
  color: #3ca70a;
  border: 1px solid #3ca70a;
}

//   .my-profile-height{min-height: calc(100vh - 110px);  }
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-cell {
  display: flex;
  align-items: center;
}

.delete-button {
  background: #f64027 !important;
  border: 1.6px solid#f64027 !important;
}

.delete-button:hover {
  color: #f64027 !important;
}

.notification-menu .MuiTabs-flexContainer {
  width: 100% !important;
  background: #fff;
  border: 1px solid rgb(221 221 221 / var(--tw-border-opacity));
  display: flex;
  padding: 10px;
  gap: 15px;
}

.notification-menu .MuiButtonBase-root {
  background-color: #eff8fe;
  border-radius: 10px;
}

.otp-text input {
  text-align: center;
}

.otp-text input:focus {
  border: none !important;
}

.profile-button .profile-button-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 165px;

  @media (max-width:975px) {
    width: 130px;
  }
}

.activenow p {
  background: #eff8fe;
  padding: 5px 20px;
  border-radius: 10px;
  text-align: center;
  text-transform: capitalize;
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}

.text-area {
  resize: none;
  overflow: hidden;
  min-height: 30px;
}

.focused {
  display: inline-block;
}

.normal-padding {
  padding: 8px 0 !important;
}

.pad-right {
  padding-right: 16px !important;
}

.more-image {
  background: $darkblue;
  color: $white;
  font-size: 16px;
  padding: 12px 21px;
  position: absolute;
  right: 0;
  bottom: 8px;
  border-radius: 16px 0px 16px 0px;
}

.remove-icon {
  position: absolute !important;
  right: 0;
  top: 0;
  background-color: $darkblue !important;
  color: $white !important;
  z-index: 100;
}

.extra-right {
  right: 17px;
}

.feed-box {
  .MuiButton-containedPrimary {
    border: none !important;
  }
}

.suggestion-tab {
  .MuiTabs-flexContainer {
    background: $lightbluebg;
    border-radius: 10px;
    width: max-content;

    @media (max-width: 1200px) {
      width: auto;
      // flex-wrap: wrap;
    }
  }

  -ms-overflow-style: none;
  scrollbar-width: 0;

  ::-webkit-scrollbar {
    display: none;
  }

  .MuiTab-textColorPrimary {
    font-size: 16px;
    font-weight: 500;
    text-transform: inherit;

    &.Mui-selected {
      background: $primary;
      color: $white;
      border-radius: 10px;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
}

.swal2-title.Swal-title-class {
  color: #000;
  font-size: 20px;
}

// .other-user-profile-img {
//   position: relative;
//   top: -59px;
//   left: 30px;
//   height: 128px;
//   width: 128px;
// }

// .other-user-profile-img img {
//   max-width: 100%;
//   height: 100%;
//   object-fit: cover;
//   border-radius: 50%;
// }
.profile-container {
  justify-content: space-between;
}

.other-user-cover-img {
  height: 275px;
}

.other-user-cover-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.user-name {
  width: auto;
}

.admin-user-avatar {
  color: #65717b !important;
}

.tab-nowrap .post-tab .MuiTabs-flexContainer {
  flex-wrap: nowrap !important;
}

.post-description {

  // word-break: break-all;
  div {
    width: 100px;
    word-wrap: break-word;
  }
}

.word-break-anywhere {
  overflow-wrap: anywhere !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #2c2c2c2c;
  border-radius: 10px;
}

.dark ::-webkit-scrollbar-track {
  background: #f1f1f10c;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3d3d3d6b;
  border-radius: 10px;
}

.dark ::-webkit-scrollbar-thumb {
  background: #bbbbbb46;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #eeeeee5d;
}

::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}

.post-tab {
  .MuiTabs-flexContainer {
    background: $lightbluebg;
    border-radius: 10px;
    width: max-content;

    @media (max-width: 1200px) {
      width: auto;
      // flex-wrap: wrap;
    }
  }
}

.play-icon {
  width: 30%;
}

.profile-user {
  // position: relative;
  top: -73px;
  left: 20px;
  z-index: 2;


  @media (max-width: 550px) {
    top: -22px;
    left: 15px;
    width: 95px;
    height: 95px;
  }
}

.send-tips {
  .BlueCirclePosition {
    position: absolute;
    top: 0;
    padding: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;

    @media (max-width: 480px) {
      padding: 6px 15px;

      .uesr1 {
        width: clamp(40px, 10vw, 60px);
        height: clamp(40px, 10vw, 60px);
      }
    }
  }
}

.explorerfans-page {
  .MuiMasonry-root {
    margin: 0 !important;

    .masonary-box {
      @media (max-width:900px) {
        width: calc(49% - 20px);
      }
    }
  }
}

.fans-message {
  .message-list {
    padding: 4px 0px;
  }
}

.cart-fans {
  .plus-minus-textbox {
    display: flex;

    .MuiButton-root {
      height: 35px;
      width: 35px;
      min-height: 35px !important;
      min-width: 35px !important;
      border-radius: 10px !important;
      padding: 6px 6px !important;
      // background-color: #F8F8F8 !important;
      color: #292D32 !important;
    }
  }
}

.order-detail {
  padding: 0.75rem 0;
}

.menu-pointer ul::before {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: 0;
  left: 14px;
  background-color: $white ;
  transform: translateY(-50%) rotate(45deg);

  @media (max-width:1200px) {
    left: 65%;
  }

  @media (max-width:768px) {
    left: 75%;
  }
}

.menu-pointer-C2 ul::before {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: 0;
  left: 14px;
  background-color: $white ;
  transform: translateY(-50%) rotate(45deg);

  @media (max-width:1200px) {
    left: 50%;
  }

  @media (max-width:768px) {
    left: 75%;
  }
}

.grid-item-margin {
  margin-right: .75rem;

  @media (max-width:600px) {
    margin-right: 0;
  }
}

.grid-item-no-margin {
  @media (max-width:900px) {
    margin-right: 0 !important;
  }
}

.post-no-padding {
  @media (max-width:1200px) {
    padding-right: 0;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .sidebar .MuiButton-containedPrimary {
    min-width: 40px;
    padding: 6px 5px;
    border-radius: 50% !important;
    min-height: 40px !important;
    height: 40px;
    width: 40px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sidebar-user-box {
  img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }
}

.sidebar {
  .MuiListItemButton-root.MuiButtonBase-root {
    margin-right: 20px;

    &:hover {
      background-color: $lightbluebg;
    }

    &.active {
      background-color: $lightbluebg;
      border-radius: 0 16px 16px 0;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-right: 4px solid $primary;
        height: 20px;
        border-radius: 10px;
        right: 0;
      }
    }
  }

  // @media (max-width:767px) {
  //   background-color: transparent !important;
  // }
}

.creator-home-module .thumbnail-text::after {
  z-index: -1;
}

.dark .sidebar .MuiListItemButton-root.MuiButtonBase-root .MuiListItemIcon-root svg path {
  stroke : $white;
}

// -----------MY CSS END--------