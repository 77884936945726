.main-text-area-comment {
  align-items: center;
  padding: 12px 16px;
}

.text-area-comment {
  height: 24px;
  resize: none;
  flex-grow: 1;
  display: flex;
  align-items: center;
  min-width: 0;
  font-size: 14px;
  line-height: 1.2;
}

.text-area-comment::placeholder {
  white-space: pre-wrap;
  line-height: 1.2;
  overflow-wrap: break-word;
}
